<!-- 病房智能交互系统 -->
<template>
  <div class="wrapper pageBox product-wrapper">
    <Banner title="床旁智能交互系统" describe="床旁智能交互系统通过安装在患者床旁的智能交互屏配以机械摇臂的方式，可灵活跳动屏幕位置。系统实现医嘱查询、报告查询、健康宣教、通话呼叫、影音娱乐、订餐服务、医生查房、院内导航、体征录入等功能，提高患者的住院体验，方便医护人员进行床旁无纸化护理工作，提高护理工作效率。" />
    <div class="product-content">
      <div class="product-name">产品亮点</div>
      <div class="introduction-content">
        <div class="introduction-item">
          <div class="item-left">
            <img class="img img1" src="@/assets/product/beside1.png" alt="">
          </div>
          <div class="item-right text-content">
            <div class="title">
              <span class="span1">患者模式</span><span>提升就医体验</span>
            </div>
            <div class="des">
              实时响应患者发起的换药呼叫、紧急呼叫、卫生间报警、请求增员等多种呼叫信息，可查询呼叫记录、进行托管、广播、喊话，支持多种患者信息展示包括患者的基本信息、医嘱信息、检查信息
            </div>
          </div>
        </div>
        <div class="introduction-item">
          <div class="item-left text-content">
            <div class="title">
              <span class="span1">医护模式</span><span>便捷护士体验</span>
            </div>
            <div class="des">
              电子床头卡代替原先的纸质床头卡，可实现患者信息的动态信息显示，包括患者的基本信息、费用信息、风险标志、饮食情况等，除了可以进行呼叫和对讲外，支持健康宣教和注意事项的接收和查看、费用的查询、医院介绍的查询满意度填写等功能
            </div>
          </div>
          <div class="item-right">
            <img class="img img2" src="@/assets/product/beside2.png" alt="">
          </div>
        </div>
        <div class="introduction-item">
          <div class="item-left">
            <img class="img img3" src="@/assets/product/beside3.png" alt="">
          </div>
          <div class="item-right text-content">
            <div class="title">
              <span class="span1">多种无纸化功能</span><span>提升护士效率</span>
            </div>
            <div class="des">
              门口屏实时显示该病房及患者的信息，包括病房号、责任医生、责任护士、液进度等，支持人脸识别和NFC刷卡 (扩展版)医护人员可登录门口屏，查看负责患者的信息、医嘱执行情况、护理信息、手术和检查安排等，方便医护人员获取工作信息。
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import Banner from '@/components/Banner';
export default {
  name: 'SmartWard',
  components: {
    Banner
  },
  data() {
    return {
    };
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {},
  methods: {

  }
};
</script>

<style lang='scss' scoped>
.product-wrapper {
  .product-content {
    background: #f7f8fe;
    .highlight {
      display: flex;
      justify-content: center;
      align-items: center;
      .highlight-item {
        background: #ffffff;
        width: 400px;
        height: 270px;
        padding: 40px;
        margin-right: 20px;
        &:last-child {
          margin-right: 0;
        }
        .item-top {
          display: flex;
          align-items: flex-end;
          margin-bottom: 20px;
          color: #333333;
          line-height: 33px;
          font-size: 24px;
          font-weight: 500;
          img {
            width: 80px;
            height: 60px;
            margin-right: 26px;
          }
        }
        .item-bottom {
          color: #5d7392;
          line-height: 22px;
          font-size: 16px;
        }
      }
    }
    .introduction-content {
      width: 1400px;
      margin: 0 auto;
      padding-bottom: 212px;
      .introduction-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        padding: 0 92px 0 62px;
        height: 540px;
        &:nth-child(2n) {
          background-color:  #eff6ff;
        }
        .img1 {
          width: 551px;
          height: 504px;
        }
        .img2 {
          width: 613px;
          height: 430px;
        }
        .img3 {
          width: 573px;
          height: 426px;
        }
        .text-content {
          width: 500px;
          .title {
            font-weight: 500;
            color: #333;
            line-height: 45px;
            font-size: 32px;
            margin-bottom: 20px;
            .span1 {
              color: #0279ff;
            }
          }
          .des {
            color: #666666;
            line-height: 22px;
            font-size: 16px;
          }
        }
      }
    }
  }
}
</style>
